import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import useUser from "../components/useUser";

import LoadingIcons from "react-loading-icons";
import Header from "../components/header";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";

function SignInBasic() {
  const [searchParams] = useSearchParams();
  const returnLT = searchParams.get("return");
  const {
    messageRunning,
    messageSuccess,
    messageError,
    login,
    isLogged,
    loginSucess,
  } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged) {
      if (window.localStorage.getItem("role") === "c")
        navigate(returnLT ? `/${returnLT}` : "/calcular");
      if (
        window.localStorage.getItem("role") === "a" ||
        window.localStorage.getItem("role") === "s"
      ) {
        navigate(returnLT ? `/${returnLT}` : "/admin");
      }
    }
  }, [isLogged, navigate, returnLT, loginSucess]);

  const [email, setUser] = useState("");
  const [ReturnLE, setReturnLE] = useState(returnLT);
  const [password, setPassword] = useState("");

  async function handleLogin2(event) {
    event.preventDefault();
    setReturnLE(false);
    login({ email, password, returnLT });
  }
  return (
    <>
      <Header />
      <Box
        position="relative"
        sx={{
          backgroundColor: "#f8f9fa",
        }}
      />
      <Box
        px={1}
        margin="50px 0"
        height="80vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card sx={{ boxShadow: 4 }}>
              <Box
                sx={{ background: "#1976D2", borderRadius: 2, zIndex: 1000 }}
                mx={3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Typography
                  variant="h4"
                  fontWeight="medium"
                  color="#fff"
                  mt={1}
                >
                  INICIAR SESIÓN
                </Typography>
              </Box>
              <Box pt={0} pb={3} px={3}>
                <Box component="form" role="form" onSubmit={handleLogin2}>
                  <Box sx={{ mb: 2 }}>
                    {messageError ? (
                      <Alert mb={2} color="error">
                        {messageError}
                      </Alert>
                    ) : (
                      false
                    )}
                    {ReturnLE ? (
                      <Alert mb={2} color="secondary">
                        <Icon color="secondary" fontSize="0">
                          info
                        </Icon>
                        <Typography color="secondary">
                          Inicia sesión para continuar...
                        </Typography>
                      </Alert>
                    ) : (
                      false
                    )}
                    {messageRunning ? (
                      <Alert mb={2} color="primary">
                        <LoadingIcons.Bars width={28} height={20} />
                        Espere un momento...
                      </Alert>
                    ) : (
                      false
                    )}
                    {messageSuccess ? (
                      <Alert mb={2} color="success">
                        <LoadingIcons.Bars width={28} height={20} />
                        Inicio de sesión exitoso. Redirigiendo...
                      </Alert>
                    ) : (
                      false
                    )}
                  </Box>

                  <Box mb={2}>
                    <TextField
                      mt={3}
                      type="email"
                      label="Correo electrónico"
                      value={email}
                      onChange={({ target }) => setUser(target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      type="password"
                      label="Contraseña"
                      value={password}
                      onChange={({ target }) => setPassword(target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2} mb={1}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="info"
                      fullWidth
                    >
                      Enviar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default SignInBasic;
