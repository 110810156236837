// Pages
import Calculadora from "./pages/principal";
import Logout from "./pages/logout";
import Admin from "./pages/admin";
import Productos from "./pages/productos";
import Historial from "./pages/historial";

const routes = [
  {
    route: "/productos",
    component: <Productos />,
  },
  {
    route: "/historial",
    component: <Historial />,
  },
  {
    route: "/admin",
    component: <Admin />,
  },
  {
    route: "/calcular",
    component: <Calculadora />,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
];

export default routes;
