import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import { useState } from "react";

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 240;
  let navItems = [];

  if (window.localStorage.getItem("role") === "c") {
    navItems = [{ name: "Salir", route: "/logout" }];
  }

  if (
    window.localStorage.getItem("role") === "a" ||
    window.localStorage.getItem("role") === "s"
  ) {
    navItems = [
      { name: "Calculadora", route: "/calcular" },
      { name: "Usuarios", route: "/admin" },
      { name: "Productos", route: "/productos" },
      { name: "Historial", route: "/historial" },
      { name: "Salir", route: "/logout" },
    ];
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Fresenius Kabi
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, i) => (
          <ListItem key={i} disablePadding>
            <Box
              component={Link}
              to={item.route}
              sx={{ color: "black!important", textDecoration: "none" }}
            >
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ mb: 2 }}>
        <Container>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            >
              <Box component={Link} to="/">
                <img
                  src="/logo.png"
                  alt="logo"
                  height={50}
                  style={{ marginY: 5 }}
                />
              </Box>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, i) => (
                <Box
                  component={Link}
                  key={i}
                  to={item.route}
                  sx={{
                    color: "white!important",
                    textDecoration: "none",
                    mx: 1,
                  }}
                >
                  <Button
                    sx={{ color: "white!important", textDecoration: "none" }}
                  >
                    {item.name}
                  </Button>
                </Box>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Header;
