import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: 24,
  p: 4,
};

function InvimaModal({ open, setOpen }) {
  return (
    <div>
      <Modal
        open={open}
        disableAutoFocus
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            alt="formulas"
            style={{ width: "92%" }}
            src="/FORMULAS INVIMA.fw.png"
          />
          <Button
            onClick={() => setOpen(false)}
            sx={{ mt: 2 }}
            variant="contained"
            color="error"
            fullWidth
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

InvimaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default InvimaModal;
