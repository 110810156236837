import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

import { useDownloadExcel } from 'react-export-table-to-excel';
import { useRef } from "react";
import { TablePagination } from "@mui/material";
import { useHistorial } from "./useHistorial";


function HistorialTable({rows}) {

 const {loaded} = useHistorial()

  const [currentPage, setCurrentPage] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
   
  };

  const handleChangeRowsPerPage = (event) => { 
    setPage(0); 
    setRowsPerPage(parseInt(event.target.value, 10));
    
   
  };


  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Users table',
    sheet: 'historial',
      
   });

   if(!loaded){
    return(
      <div>
        <p>Cargando...</p>
      </div>
    )
  }
  
 
 
const filteredTable = () => {
    
    if(search.length === 0)
         return rows.slice(currentPage, currentPage + 25);

         const filtered = rows.filter(row => row.data.toLowerCase().includes(search));
           console.log(filtered)
          return filtered.slice(currentPage, currentPage + 25)
        
 
   }

   const nextPage = () =>{
  if(rows.filter(row => row.data.toLowerCase().includes(search)).length  > currentPage + 25 );

   setCurrentPage (currentPage + 25)
   }
   const prevPage = () =>{
    if(currentPage > 0)
    setCurrentPage (currentPage - 25)
   }

   const onSearchChange = ({target})=>{
     setCurrentPage(0);
     setSearch(target.value)
   }
  
  return ( <div>

    <button onClick={prevPage}>Anterior</button>
    &nbsp;
    <button onClick={nextPage}>Siguiente</button>
    &nbsp;
 <input 
 type="text" 
 placeholder="Buscar"
 value={search}
 onChange={onSearchChange} />

<TablePagination
           
          rowsPerPageOptions={[5, 10, 25,{ value: -1, label: 'All' }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
         
       />


        <button style={{
              color:'white',
              background:'#0063BE',
            }} onClick={onDownload}> Export excel 
        </button>
    <TableContainer component={Paper}
    
    >                 
     <Table ref={tableRef} sx={{ minWidth: 650 }} aria-label="simple table">
      
        <TableHead>   
          <TableRow>
            <TableCell >Nombre</TableCell>
            <TableCell  align="right">Fecha</TableCell>
            <TableCell align="right">Producto (F)</TableCell>
            <TableCell align="right">Precio (F)</TableCell>
            <TableCell align="right">Calorías (F)</TableCell>
            <TableCell align="right">Producto (otro)</TableCell>
            <TableCell align="right">Precio (otro)</TableCell>
            <TableCell align="right">Calorías (otro)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody 
         rowsPerPageOptions={[10, 50, { value: -1, label: 'All' }]}
        
        >
          {filteredTable().map((row) => {
            const row2 = JSON.parse(row.data);
            const date = new Date(row.fecha * 1 );
           const fecha = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}/${date.getHours()}:${date.getMinutes()}`;

            return (
              <TableRow
                  key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell  component="th" scope="row">{row2.user}</TableCell>
                <TableCell align="right">{fecha}</TableCell>
                <TableCell align="right">{row2.inputF1}</TableCell>
                <TableCell align="right">{row2.inputF2}</TableCell>
                <TableCell align="right">{row2.inputF3}</TableCell>
                <TableCell align="right">{row2.inputO1}</TableCell>
                <TableCell align="right">{row2.inputO2}</TableCell>
                <TableCell align="right">{row2.inputO3}</TableCell>
              </TableRow>
              
            );
         }).sort((a,b) => new Date(a.fecha) > new Date(b.fecha)).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        </TableBody>
      </Table>
    </TableContainer>

  

    </div>
   //
  );
}

HistorialTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default HistorialTable;
