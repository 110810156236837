import axios from "axios";
import FormData from "form-data";

const peticion = async (
  endpoint,
  type,
  token = null,
  body = null,
  plain = false,
  customurl = null
) => {
  let tokenvar = ``;
  let baseurl;
  let config;
  if (token) {
    tokenvar = `?token=${window.localStorage.getItem("token")}`;
  }
  baseurl = `https://calculadorafresenius.tech/api/${endpoint}${tokenvar}`;
//baseurl = `https://api.calculadorafresenius.tech:4002/rest/${endpoint}${tokenvar}`;
  if (customurl != null) {
    baseurl = customurl;
  }
  if (type === "post") {
    const data2 = new FormData();
    Object.entries(body).map(([key, value]) => {
      data2.append(key, value);
      return false;
    });
    config = {
      method: type,
      url: baseurl,
      headers: { "Content-Type": "multipart/form-data" },
      data: data2,
    };
  } else if (type === "get") {
    config = {
      method: type,
      url: baseurl,
    };
  }
  const { data } = await axios(config);
  if (endpoint === "login") {
    window.localStorage.setItem("token", data.token);
  }
  if (endpoint !== "logout") {
    if (data.error === "Invalid Token.") {
      window.localStorage.clear();
      window.location.replace(`/`);
    }
    if (data.error === "Invalid Role.") {
      window.location.replace(`/`);
    }
  }
  console.log(`Petición hecha: ${endpoint}`);
  return data;
};

export default peticion;
