import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";

function ConfirmDialog({ state, deleteU, setDialogState, loadingDialog }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={state}
      onClose={() => setDialogState(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Eliminar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Estas seguro que quieres eliminarlo del sistema?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setDialogState(false)}>
          Cancelar
        </Button>
        {loadingDialog ? (
          <LoadingButton loading variant="contained">
            Aceptar
          </LoadingButton>
        ) : (
          <Button variant="contained" onClick={() => deleteU(false)}>
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  setDialogState: PropTypes.func.isRequired,
  state: PropTypes.bool.isRequired,
  loadingDialog: PropTypes.bool.isRequired,
  deleteU: PropTypes.func.isRequired,
};

export default ConfirmDialog;
