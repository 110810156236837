import Header from "../components/header";
import { Container } from "@mui/system";
import { Alert, Grid, Typography } from "@mui/material";
import LoadingIcons from "react-loading-icons";

import HistorialTable from "../components/HistorialTable";
import { useHistorial } from "../components/useHistorial";


function App() {
const { loaded,
  hasError,
  rows,
  
  } = useHistorial();

  return (
    <>
      
      
      <Container sx={{ marginTop: 10 }} spacing={4}>
    

         <Header logout />
        <Grid container>
          {hasError ? (
            <Grid item xs={12}>
              <Alert severity="error" sx={{ width: "100%" }}>
                {hasError}
              </Alert>
            </Grid>
          ) : (
            false
          )}
          {!hasError && !loaded ? (
            <Grid container item justifyContent="center">
              <LoadingIcons.TailSpin
                height={65}
                width={65}
                stroke="#162D3C"
                fill="#162D3C"
              />
            </Grid>
          ) : (
            false
          )}
          {loaded ? (
            <>
            
              <Grid 
                container
                alignItems="center"
                justifyContent="space-between"
                item
                xs={12}
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: "#0063BE",
                      marginX: 2,
                      marginTop: 1,
                      textAlign: "left",
                    }}
                  >
                    Historial de Consultas
                  </Typography>
                </Grid>
                <Grid item xs={12}  >
                  <HistorialTable  rows={rows}/>
                </Grid>
              </Grid>
            </>
          ) : (
            false
          )}
        </Grid>
       
      </Container>
     
     
    </>
  );
}

export default App;
