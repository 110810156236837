import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

function UsuariosModal({
  open,
  submit,
  setOpen,
  modalError,
  dataModal,
  setDataModal,
  submitModal,
}) {
  return (
    <Modal
      open={open}
      disableAutoFocus
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "75%", sm: 400 },
          bgcolor: "white",
          borderRadius: 3,
          border: "2px solid #fff!important",
          boxShadow: 4,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {dataModal.mode === "edit" ? "Editar Producto" : "Añadir Producto"}
        </Typography>
        <Grid container mt={2} spacing={2}>
          {modalError ? (
            <Grid item xs={12}>
              <Alert severity="error">{modalError}</Alert>
            </Grid>
          ) : (
            false
          )}
          <Grid item xs={12}>
            <TextField
              type="text"
              label="Nombre"
              disabled={dataModal.type === "fijos" ? true : false}
              value={dataModal.name}
              onChange={({ target }) =>
                setDataModal((list) => ({
                  ...list,
                  name: target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          {dataModal.type === "fijos" ? (
            <>
              {" "}
              <Grid item xs={12}>
                <TextField
                  type="number"
                  inputProps={{ min: 0.1 }}
                  label="Valor"
                  value={dataModal.suministro}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      suministro: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Presentación"
                  value={dataModal.presentacionGr}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      presentacionGr: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Rendimiento Por Porciones"
                  value={dataModal.rendimientoPorciones}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      rendimientoPorciones: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Tamaño porción"
                  value={dataModal.tamanoPorcion}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      tamanoPorcion: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Porción Líquida"
                  value={dataModal.porcionLiquida}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      porcionLiquida: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Cantidad de proteína por porción"
                  value={dataModal.cantidadProteinaPorPorcion}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      cantidadProteinaPorPorcion: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Calorías por porción"
                  value={dataModal.caloriasPorPorcion}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      caloriasPorPorcion: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Calorías por ML"
                  value={dataModal.CaloriasPorMl}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      CaloriasPorMl: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  label="Proteína por ML"
                  value={dataModal.ProteinaPorMl}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      ProteinaPorMl: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                   Categorias
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dataModal.categoria}
                    label="Tipo de producto"
                    onChange={({ target }) =>
                      setDataModal((list) => ({
                        ...list,
                        categoria: target.value,
                      }))
                    }
                  >
                    
                    <MenuItem value="pediatrica">Pediatricas</MenuItem>
                    <MenuItem value="polimerica">Polimericas</MenuItem>
                    <MenuItem value="diabetes">Diabetes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                   Tipo de producto
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dataModal.type}
                    label="Tipo de producto"
                    onChange={({ target }) =>
                      setDataModal((list) => ({
                        ...list,
                        type: target.value,
                      }))
                    }
                  >
                    
                    <MenuItem value="fresenius">Fresenius</MenuItem>
                    <MenuItem value="otro">Competencia</MenuItem>
                    <MenuItem value="fijo">Fijo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Suministro
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dataModal.suministro}
                    label="Suministro"
                    onChange={({ target }) =>
                      setDataModal((list) => ({
                        ...list,
                        suministro: target.value,
                      }))
                    }
                  >
                    <MenuItem value="liquido">Liquido</MenuItem>
                    <MenuItem value="polvo">Polvo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  label="Tipo de envase"
                  value={dataModal.TipoEnvase}
                  onChange={({ target }) =>
                    setDataModal((list) => ({
                      ...list,
                      TipoEnvase: target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
            </>
          )}
          {!submit ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => submitModal()}
              >
                Guardar
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <LoadingButton loading variant="contained" fullWidth>
                Guardar
              </LoadingButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

UsuariosModal.propTypes = {
  submit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  modalError: PropTypes.node.isRequired,
  dataModal: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  setDataModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func.isRequired,
};

export default UsuariosModal;
