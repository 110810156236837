import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../components/useUser";

function Logout() {
  const { logout, isLogged } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged) {
      logout();
      navigate("/");
    } else {
      navigate("/");
    }
  }, [isLogged, navigate, logout]);

  return <></>;
}

export default Logout;
