import { useCallback, useState } from "react";
import peticion from "./api";
import validator from "validator";

export default function useUser() {
  const [jwt, setJWT] = useState(
    window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("NameUser") !== null &&
      window.localStorage.getItem("role") !== null &&
      window.localStorage.getItem("token") !== undefined &&
      window.localStorage.getItem("NameUser") !== undefined &&
      window.localStorage.getItem("role") !== undefined
  );
  const [loginSucess, setLoginSucess] = useState(false);
  const [state, setState] = useState({
    loading: false,
    error: false,
    sucess: false,
  });

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const login = useCallback(
    async ({ email, password }) => {
      let run = true;
      if (!validator.isEmail(email)) {
        setState({ loading: false, error: "Email no válido.", sucess: false });
        run = false;
      }
      if (!password.toString().trim().length) {
        setState({
          loading: false,
          error: "Contraseña no ingresada.",
          sucess: false,
        });
        run = false;
      }
      if (state.loading) {
        run = false;
      }
      if (run) {
        try {
          setState({ loading: true, error: false, sucess: false });
          const body = { email: email, password: password };
          const loginRequest = await peticion("login", "post", null, body);
          if (loginRequest.response === "error") {
            window.localStorage.clear();
            setJWT(false);
            setState({
              loading: false,
              error: loginRequest.error,
              sucess: false,
            });
          }
          if (loginRequest.response === "ok") {
            window.localStorage.setItem("NameUser", loginRequest.data.name);
            window.localStorage.setItem("role", loginRequest.data.role);
            setState({ loading: false, error: false, sucess: true });
            await sleep(600);
            setLoginSucess(true);
            setJWT(true);
          }
        } catch (e) {
          window.localStorage.clear();
          setJWT(false);
          setState({
            loading: false,
            error: "Error de conexión, intente nuevamente.",
            sucess: false,
          });
        }
      }
    },
    [setJWT, state]
  );

  const logout = useCallback(async () => {
    peticion("logout", "get", true, null);
    setJWT(false);
    window.localStorage.clear();
  }, [setJWT]);

  return {
    isLogged: Boolean(jwt),
    loginSucess: Boolean(loginSucess),
    messageRunning: state.loading && !state.sucess && !state.error,
    messageError: state.error,
    messageSuccess: state.sucess,
    login,
    logout,
  };
}
