import  { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import peticion from './api';
import useUser from './useUser';

export const useHistorial = () => {

    const [loaded, setLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [rows, setRows] = useState(false);
    const { isLogged } = useUser();
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLogged) {
        navigate("/logout");
      }
      if (
        window.localStorage.getItem("role") !== "a" &&
        window.localStorage.getItem("role") !== "s"
      ) {
        navigate("/calcular");
      }
    }, [isLogged, navigate]);
    useEffect(() => {
      peticion("get_history", "get", true, null)
        .then((getData) => {
          if (getData.response === "ok") {
            setRows(getData.data);
            console.log(getData)
            setLoaded(true); 
          } else {
            setHasError(getData.error);
          }
        })
        .catch(() =>
          setHasError(
            "Ha ocurrido un error, por favor actualice la página e intente nuevamente."
          )
        );
    }, []);

  
  return (
   {peticion,
    loaded,
    setLoaded,
    hasError,
    setHasError,
    rows,
    setRows,
    isLogged,
    navigate,
   
   }
  )
}
