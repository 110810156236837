import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

function UsuariosModal({
  open,
  submit,
  setOpen,
  modalError,
  dataModal,
  setDataModal,
  submitModal,
}) {
  return (
    <Modal
      open={open}
      disableAutoFocus
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "75%", sm: 400 },
          bgcolor: "white",
          borderRadius: 3,
          border: "2px solid #fff!important",
          boxShadow: 4,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {dataModal.mode === "edit" ? "Editar Usuario" : "Añadir Usuario"}
        </Typography>
        <Grid container mt={2} spacing={2}>
          {modalError ? (
            <Grid item xs={12}>
              <Alert severity="error">{modalError}</Alert>
            </Grid>
          ) : (
            false
          )}
          <Grid item xs={12}>
            <TextField
              type="text"
              label="Nombre"
              value={dataModal.name}
              onChange={({ target }) =>
                setDataModal((list) => ({
                  ...list,
                  name: target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="email"
              label="Correo electrónico"
              value={dataModal.email}
              onChange={({ target }) =>
                setDataModal((list) => ({
                  ...list,
                  email: target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              label={
                dataModal.mode === "add"
                  ? "Contraseña"
                  : "Contraseña (dejar en blanco si desea mantenerla)"
              }
              value={dataModal.password}
              onChange={({ target }) =>
                setDataModal((list) => ({
                  ...list,
                  password: target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Rol de usuario
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dataModal.role}
                label="Rol de usuario"
                onChange={({ target }) =>
                  setDataModal((list) => ({
                    ...list,
                    role: target.value,
                  }))
                }
              >
                <MenuItem value="a">Administrador</MenuItem>
                <MenuItem value="c">Cliente</MenuItem>
                <MenuItem value="s">Supervisor</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!submit ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => submitModal()}
              >
                Guardar
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <LoadingButton loading variant="contained" fullWidth>
                Guardar
              </LoadingButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

UsuariosModal.propTypes = {
  submit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  modalError: PropTypes.node.isRequired,
  dataModal: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  setDataModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func.isRequired,
};

export default UsuariosModal;
