import Header from "../components/header";
import { Container } from "@mui/system";
import useUser from "../components/useUser";
import { useEffect, useState } from "react";
import { Alert, Grid, Icon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import peticion from "../components/api";
import LoadingIcons from "react-loading-icons";

import ConfirmDialog from "../components/ConfirmDialog";
import ProductosTable from "../components/ProductosTable";
import ProductosModal from "../components/ProductosModal";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(false);
  const [dialogState, setDialogState] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  const { isLogged } = useUser();
  const navigate = useNavigate();

  const [dataModal, setDataModal] = useState({
    mode: "add",
    name: "",
    presentacionGr: "",
    rendimientoPorciones: "",
    tamanoPorcion: "",
    porcionLiquida: "",
    cantidadProteinaPorPorcion: "",
    caloriasPorPorcion: "",
    CaloriasPorMl: "",
    ProteinaPorMl: "",
    type: "",
    suministro: "",
    TipoEnvase: "",
    categoria: "",
  });

  function findrow(id) {
    for (let i = 0; i < rows.length; i += 1) {
      if (rows[i].id === id) return rows[i];
    }
  }

  function deleteU(id) {
    console.log(`User ID: ${idDelete}`);
    setDialogState(Boolean(id));
    if (id) {
      setIdDelete(id);
      setLoadingDialog(false);
    } else {
      setLoadingDialog(true);
      peticion("delete_product", "post", true, {
        id: idDelete,
      })
        .then((getData) => {
          if (getData.response === "ok") {
            window.location.reload();
          } else {
            setHasError(getData.error);
          }
          setLoadingDialog(false);
        })
        .catch(() => {
          setLoadingDialog(false);
          setHasError("Ha ocurrido un error de conexión, intente nuevamente.");
        });
    }
  }

  function submitModal() {
    setSubmit(true);
    if (dataModal.mode === "add") {
      peticion("add_product", "post", true, {
        name: dataModal.name,
        presentacionGr: dataModal.presentacionGr,
        rendimientoPorciones: dataModal.rendimientoPorciones,
        tamanoPorcion: dataModal.tamanoPorcion,
        porcionLiquida: dataModal.porcionLiquida,
        cantidadProteinaPorPorcion: dataModal.cantidadProteinaPorPorcion,
        caloriasPorPorcion: dataModal.caloriasPorPorcion,
        CaloriasPorMl: dataModal.CaloriasPorMl,
        ProteinaPorMl: dataModal.ProteinaPorMl,
        type: dataModal.type,
        suministro: dataModal.suministro,
        TipoEnvase: dataModal.TipoEnvase,
        categoria: dataModal.categoria
      })
        .then((getData) => {
          if (getData.response === "ok") {
            window.location.reload();
          } else {
            setModalError(getData.error);
          }
          setSubmit(false);
        })
        .catch(() => {
          setSubmit(false);
          setModalError(
            "Ha ocurrido un error de conexión, intente nuevamente."
          );
        });
    }
    if (dataModal.mode === "edit") {
      peticion("edit_product", "post", true, {
        name: dataModal.name,
        presentacionGr: dataModal.presentacionGr,
        rendimientoPorciones: dataModal.rendimientoPorciones,
        tamanoPorcion: dataModal.tamanoPorcion,
        porcionLiquida: dataModal.porcionLiquida,
        cantidadProteinaPorPorcion: dataModal.cantidadProteinaPorPorcion,
        caloriasPorPorcion: dataModal.caloriasPorPorcion,
        CaloriasPorMl: dataModal.CaloriasPorMl,
        ProteinaPorMl: dataModal.ProteinaPorMl,
        type: dataModal.type,
        suministro: dataModal.suministro,
        TipoEnvase: dataModal.TipoEnvase,
        id: dataModal.id,
        categoria: dataModal.categoria,
      })
        .then((getData) => {
          if (getData.response === "ok") {
            window.location.reload();
          } else {
            setModalError(getData.error);
          }
          setSubmit(false);
        })
        .catch(() => {
          setSubmit(false);
          setModalError(
            "Ha ocurrido un error de conexión, intente nuevamente."
          );
        });
    }
  }

  function Cmodal(mode, id) {
    setModalError(false);
    setSubmit(false);
    console.log(dataModal);
    if (mode === "edit") {
      const row = findrow(id);
      setDataModal({
        mode: "edit",
        name: row.name,
        presentacionGr: row.presentacionGr,
        rendimientoPorciones: row.rendimientoPorciones,
        tamanoPorcion: row.tamanoPorcion,
        porcionLiquida: row.porcionLiquida,
        cantidadProteinaPorPorcion: row.cantidadProteinaPorPorcion,
        caloriasPorPorcion: row.caloriasPorPorcion,
        CaloriasPorMl: row.CaloriasPorMl,
        ProteinaPorMl: row.ProteinaPorMl,
        type: row.type,
        suministro: row.suministro,
        TipoEnvase: row.TipoEnvase,
        id: id,
        categoria: row.categoria
      });
    } else if (mode === "add") {
      setDataModal({
        mode: "add",
        name: "",
        password: "",
        email: "",
        role: "",
      });
    }
    setOpen(true);
  }

  useEffect(() => {
    if (!isLogged) {
      navigate("/logout");
    }
    if (
      window.localStorage.getItem("role") !== "a" &&
      window.localStorage.getItem("role") !== "s"
    ) {
      navigate("/calcular");
    }
  }, [isLogged, navigate]);

  useEffect(() => {
    peticion("get_products", "get", true, null)
      .then((getData) => {
        if (getData.response === "ok") {
          setRows(getData.data);
          setLoaded(true);
        } else {
          setHasError(getData.error);
        }
      })
      .catch(() =>
        setHasError(
          "Ha ocurrido un error, por favor actualice la página e intente nuevamente."
        )
      );
  }, []);

  return (
    <>
      <Header logout />
      <Container sx={{ marginTop: 10 }} spacing={4}>
        <Grid container>
          {hasError ? (
            <Grid item xs={12}>
              <Alert severity="error" sx={{ width: "100%" }}>
                {hasError}
              </Alert>
            </Grid>
          ) : (
            false
          )}
          {!hasError && !loaded ? (
            <Grid container item justifyContent="center">
              <LoadingIcons.TailSpin
                height={65}
                width={65}
                stroke="#162D3C"
                fill="#162D3C"
              />
            </Grid>
          ) : (
            false
          )}
          {loaded ? (
            <>
              <ProductosModal
                open={open}
                setOpen={setOpen}
                modalError={modalError}
                dataModal={dataModal}
                submit={submit}
                setDataModal={setDataModal}
                submitModal={submitModal}
              ></ProductosModal>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                item
                xs={12}
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: "#0063BE",
                      marginX: 2,
                      marginTop: 1,
                      textAlign: "left",
                    }}
                  >
                    Productos
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    onClick={() => Cmodal("add")}
                    sx={{
                      background: "#0063BE",
                      paddingX: 2.8,
                      cursor: "pointer",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      paddingY: 0.8,
                      borderRadius: 2,
                      textAlign: "center",
                    }}
                    component="h2"
                  >
                    <Icon>add</Icon>Añadir Nuevo
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ProductosTable
                    rows={rows}
                    Cmodal={Cmodal}
                    deleteU={deleteU}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            false
          )}
        </Grid>
      </Container>
      <ConfirmDialog
        state={dialogState}
        deleteU={deleteU}
        loadingDialog={loadingDialog}
        setDialogState={setDialogState}
      />
    </>
  );
}

export default App;
