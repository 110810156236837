import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

function Iresult({ name, f1, f2, o1, o2, bgcolor }) {
  return (
    <>
      <Grid container item justifyContent="center" xs={12} mt={2}>
        <Typography
          variant="h6"
          sx={{
            background: bgcolor,
            paddingX: 2.5,
            color: "white",
            paddingY: 0.5,
            borderRadius: 2,
            width: "100%",
            textAlign: "center",
          }}
          component="h2"
        >
          {name}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      > 
      {f1 ? (
          <Grid item xs={6} lg={3}>
            <Typography
              variant="body1"
              sx={{
                color: "#3382CB",
                fontWeight: "bold",
                textAlign: "center",
              }}
              component="h2"
            >
              {f1}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#3382CB",
                border: "2px solid #3382CB",
                borderRadius: 2,
                fontWeight: "bold",
                textAlign: "center",
              }}
              component="h2"
            >
              {f2}
            </Typography>
          </Grid>
        ) : (
          false
        )}
        
        {o1 ? (
          <>
            <Grid item xs={6} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  color: "gray",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                component="h2"
              >
                {o1}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "gray",
                  border: "2px solid gray",
                  borderRadius: 2,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                component="h2"
              >
                {o2}
              </Typography>
            </Grid>
          </>
        ) : (
          false
        )}
        
      </Grid>
    </>
  );
}

Iresult.defaultProps = {
  bgcolor: "#3382CB",
};

Iresult.propTypes = {
  name: PropTypes.node.isRequired,
  f1: PropTypes.node.isRequired,
  f2: PropTypes.node.isRequired,
  o1: PropTypes.node.isRequired,
  o2: PropTypes.node.isRequired,
  bgcolor: PropTypes.node,
};

export default Iresult;
