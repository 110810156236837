import Header from "../components/header";
import { Container } from "@mui/system";
import useUser from "../components/useUser";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Iresult from "../components/iresults";
import peticion from "../components/api";
import LoadingIcons from "react-loading-icons";
import InvimaModal from "../components/InvimaModal";

{/*import { useCategorias } from "../hooks/useCategorias";*/}


function App() {
  const [inputF1, setInputF1] = useState("");
  const [inputF2, setInputF2] = useState("");
  const [inputF3, setInputF3] = useState("");
  const [inputO1, setInputO1] = useState("");
  const [inputO2, setInputO2] = useState("");
  const [inputO3, setInputO3] = useState("");
  const [inputA1, setInputA1] = useState("1");
  const [inputG1, setInputG1] = useState("1");
  const [inputG2, setInputG2] = useState("1");
  const [inputG3, setInputG3] = useState("1");
  const [inputG4, setInputG4] = useState("1");
  const [inputG5, setInputG5] = useState("1");
  const [inputG6, setInputG6] = useState("1");
  const [productos, setProductos] = useState([]);
  const [invima, setInvima] = useState(false);
  const [column1, setColumn1] = useState(false);
  const [column2, setColumn2] = useState(false);
  const [column3, setColumn3] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [RR, setRR] = useState({ F: [], O: [], A: [] });
  const [showResult, setShowResult] = useState(false);
  const [hasError, setHasError] = useState(false);
   
  const { isLogged } = useUser();
  const navigate = useNavigate();
 

  useEffect(() => {
    if (!isLogged) {
      navigate("/logout");
    }
  }, [isLogged, navigate]);

  useEffect(() => {
    peticion("obtener_productos", "get", true, null)
      .then((getData) => {
        if (getData.response === "ok") {
          setProductos(getData.data);
        
          setLoaded(true);
          
          
        } else {
          setHasError(getData.error);
        }
      })
      .catch(() =>
        setHasError(
          "Ha ocurrido un error, por favor actualice la página e intente nuevamente."
        )
      );
  }, []);
 
  
  const fresenius = productos.map((item) => item.categoria === "polimerica" && item.type === "fresenius" ? 
  (
    <MenuItem key={item.id} value={item.id}>
    {item.name} - {item.presentacionGr}ML
   </MenuItem>
  ) : (
  false
  )  

)  
  const diabetes=  productos.map((item) =>
  item.categoria === "diabetes" && item.type === "fresenius" ? (
    <MenuItem  key={item.id} value={item.id}>
    {item.name} - {item.presentacionGr}ML
   </MenuItem>
  ) : (
  false
  )  
  );
  const pediatricas = productos.map((item) =>
  item.categoria === "pediatrica" && item.type === "fresenius" ? (
    <MenuItem key={item.id} value={item.id}>
    {item.name } - {item.presentacionGr }ML
   </MenuItem>
  ) : (
  false
  )  
  );

const ped = productos.map((item)=>console.log(item) 
)
console.log(ped)

  useEffect(() => {
    const formatterPeso = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });
    const resultados = { F: [], O: [], A: [], G: [] };
    if (
      inputF1 !== "" &&
      inputF2 !== "" &&
      inputF3 !== "" &&
      inputO1 !== "" &&
      inputO2 !== "" &&
      inputO3 !== ""
    ) {
      for (let i = 0; i < 2; i++) {
        const producto = i === 0 ? "F" : "O";
        let in1, in2, in3;
        if (producto === "F") {
          in1 = inputF1;
          in2 = inputF2;
          in3 = inputF3;
        }
        if (producto === "O") {
          in1 = inputO1;
          in2 = inputO2;
          in3 = inputO3;
        }
        const productosin = productos.find((x) => x.id === in1);
        const porciones = in3 / productosin.caloriasPorPorcion;
        resultados[producto][0] = (
          productosin.cantidadProteinaPorPorcion * porciones
        ).toFixed();
        resultados[producto][1] = formatterPeso.format(
          productosin.suministro === "polvo"
            ? (
                in2 /
                productosin.rendimientoPorciones /
                productosin.cantidadProteinaPorPorcion
              ).toFixed()
            : (in2 / productosin.cantidadProteinaPorPorcion).toFixed()
        );

        resultados[producto][2] = porciones * productosin.tamanoPorcion;
        resultados[producto][6] =
          resultados[producto][2] / productosin.presentacionGr;
        resultados[producto][3] = resultados[producto][6] * 30;
        resultados[producto][4] = resultados[producto][3] * in2;
        resultados[producto][5] = resultados[producto][4] / 30;
      }

      resultados["A"][0] = `${(
        (resultados["F"][4] / resultados["O"][4]).toFixed(2) * 100
      ).toFixed()}%`;
      resultados["A"][1] = (resultados["O"][4] - resultados["F"][4]) * inputA1;
      resultados["A"][2] = resultados["A"][1] * 3;
      resultados["A"][3] = resultados["A"][1] * 12;
      resultados["G"][1] =
        inputG1 * productos.find((x) => x.name === "Agua").suministro;
      resultados["G"][2] =
        inputG2 * productos.find((x) => x.name === "Guantes").suministro;
      resultados["G"][3] =
        inputG3 *
        productos.find((x) => x.name === "Recursos Humanos").suministro;
      resultados["G"][4] =
        inputG4 * productos.find((x) => x.name === "Licuadora").suministro;
      resultados["G"][5] =
        inputG5 * productos.find((x) => x.name === "Energia").suministro;
      resultados["G"][6] =
        resultados["G"][1] +
        resultados["G"][2] +
        resultados["G"][3] +
        resultados["G"][4] +
        resultados["G"][5] +
        resultados["O"][5];
      resultados["G"][7] = resultados["G"][6] * inputG6;
      resultados["G"][8] = resultados["G"][6] * 30;
      resultados["G"][9] = resultados["G"][8] * inputG6;
      resultados["G"][10] = resultados["G"][6] * 365;
      resultados["G"][11] = resultados["G"][10] * inputG6;
      resultados["G"][12] = (resultados["G"][8] - resultados["O"][4]) * inputG6;
      resultados["G"][13] = (resultados["F"][4] - resultados["G"][8]) * inputG6;
      resultados["G"][14] = resultados["G"][13] * 3;
      resultados["G"][15] = resultados["G"][13] * 12;
      resultados["G"][16] = resultados["O"][4] * inputG6;
      resultados["G"][17] = resultados["F"][4] * inputG6;

      resultados["O"][0] = `${resultados["O"][0]} gr`;
      resultados["F"][0] = `${resultados["F"][0]} gr`;
      resultados["O"][2] = resultados["O"][2].toFixed();
      resultados["F"][2] = resultados["F"][2].toFixed();
      resultados["O"][3] = resultados["O"][3].toFixed();
      resultados["F"][3] =
        resultados["F"][3] < 1
          ? resultados["F"][3].toFixed(3)
          : resultados["F"][3].toFixed();
      resultados["F"][4] = formatterPeso.format(
        resultados["F"][4].toFixed().toString().replaceAll(".", "")
      );
      resultados["O"][4] = formatterPeso.format(
        resultados["O"][4].toFixed().toString().replaceAll(".", "")
      );
      resultados["F"][5] = formatterPeso.format(
        resultados["F"][5].toFixed().toString().replaceAll(".", "")
      );
      resultados["O"][5] = formatterPeso.format(
        resultados["O"][5].toFixed().toString().replaceAll(".", "")
      );
      resultados["F"][6] = `${
        resultados["F"][6] < 1
          ? resultados["F"][6].toFixed(4)
          : resultados["F"][6].toFixed(1)
      } ${productos.find((x) => x.id === inputF1).TipoEnvase}`;
      resultados["O"][6] = `${resultados["O"][6].toFixed(1)} ${
        productos.find((x) => x.id === inputO1).TipoEnvase
      }`;
      resultados["O"][2] = `${resultados["O"][2]} ${
        productos.find((x) => x.id === inputO1).suministro === "liquido"
          ? "ml"
          : "gr"
      }`;
      resultados["F"][2] = `${resultados["F"][2]} ${
        productos.find((x) => x.id === inputF1).suministro === "liquido"
          ? "ml"
          : "gr"
      }`;
      resultados["A"][1] = formatterPeso.format(
        resultados["A"][1].toFixed().toString().replaceAll(".", "")
      );
      resultados["A"][2] = formatterPeso.format(
        resultados["A"][2].toFixed().toString().replaceAll(".", "")
      );
      resultados["A"][3] = formatterPeso.format(
        resultados["A"][3].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][1] = formatterPeso.format(
        resultados["G"][1].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][2] = formatterPeso.format(
        resultados["G"][2].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][3] = formatterPeso.format(
        resultados["G"][3].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][4] = formatterPeso.format(
        resultados["G"][4].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][5] = formatterPeso.format(
        resultados["G"][5].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][6] = formatterPeso.format(
        resultados["G"][6].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][7] = formatterPeso.format(
        resultados["G"][7].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][8] = formatterPeso.format(
        resultados["G"][8].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][9] = formatterPeso.format(
        resultados["G"][9].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][10] = formatterPeso.format(
        resultados["G"][10].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][11] = formatterPeso.format(
        resultados["G"][11].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][12] = formatterPeso.format(
        resultados["G"][12].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][13] = formatterPeso.format(
        resultados["G"][13].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][14] = formatterPeso.format(
        resultados["G"][14].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][15] = formatterPeso.format(
        resultados["G"][15].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][16] = formatterPeso.format(
        resultados["G"][16].toFixed().toString().replaceAll(".", "")
      );
      resultados["G"][17] = formatterPeso.format(
        resultados["G"][17].toFixed().toString().replaceAll(".", "")
      );
      setRR(resultados);
    }
  }, [
    inputF1,
    inputF2,
    inputF3,
    inputO1,
    inputO2,
    inputO3,
    inputA1,
    inputG1,
    inputG2,
    inputG3,
    inputG4,
    inputG5,
    inputG6,
    productos,
  ]);

  const nombresResultados = [
    ["Aporte en gramos de proteína por cada tratamiento por Día", "0"],
    [
      "Precio de cada gramo de proteína por tratamiento seleccionado por día",
      "1",
    ],
    ["Cantidad de tratamiento diario gr o ml", "2"],
    ["Cantidad de tratamiento diario und", "6"],
    ["Cantidad de tratamiento mensual", "3"],
    ["Valor tratamiento dia", "5"],
    ["Valor final tratamiento mes", "4"],
  ];

  function guardar() {
    peticion("add_history", "post", true, {
      data: JSON.stringify({
        inputF1: `${productos.find((x) => x.id === inputF1).name} - ${
          productos.find((x) => x.id === inputF1).presentacionGr
        } ${
          productos.find((x) => x.id === inputF1).suministro === "polvo"
            ? "GR"
            : "ML"
        }`,
        inputO1: `${productos.find((x) => x.id === inputF1).name} - ${
          productos.find((x) => x.id === inputF1).presentacionGr
        } ${
          productos.find((x) => x.id === inputF1).suministro === "polvo"
            ? "GR"
            : "ML"
        }`,
        inputF2: inputF2,
        inputF3: inputF3,
        inputO2: inputO2,
        inputO3: inputO3,
        user: window.localStorage.getItem("NameUser"),
      }),
    })
      .then((getData) => {
        if (getData.response === "error") {
          setHasError(getData.error);
        }
      })
      .catch(() =>
        setHasError("Ha ocurrido un error al guardar los resultados.")
      );
  }

  function resultados() {
    guardar();
    if (
      (inputF1.length,
      inputF2.length,
      inputF3.length,
      inputO1.length,
      inputO2.length,
      inputO3.length > 0)
    ) {
      setHasError(false);
      setShowResult(true);
      setTimeout(() => {
        window.scrollTo(
          0,
          document.querySelector("#card_Principal").offsetHeight + 120
        );
      }, 300);
    } else {
      setHasError("Por favor completa todos los campos para continuar");
    }
  }

  
  
  return (
    <>
      <Header role="cliente" />
      <Container sx={{ marginTop: 10 }} spacing={4}>
        <Grid container>
          {hasError ? (
            <>
              <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
                {hasError}
              </Alert>
            </>
          ) : (
            false
          )}
          {!hasError && !loaded ? (
            <Grid container item justifyContent="center">
              <LoadingIcons.TailSpin
                height={65}
                width={65}
                stroke="#162D3C"
                fill="#162D3C"
              />
            </Grid>
          ) : (
            false
          )}
          {loaded ? (
            <>
              <Grid container justifyContent="center" item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#0063BE",
                    paddingX: 2.8,
                    color: "white",
                    paddingY: 0.8,
                    borderRadius: 2,
                    textAlign: "center",
                  }}
                  component="h2"
                >
                  Hola {window.localStorage.getItem("NameUser")}, Empecemos!
                </Typography>
              </Grid>
              <Card
                id="card_Principal"
                sx={{
                  width: "100%",
                  boxShadow: 4,
                  padding: 3,
                  mt: 2,
                  borderRadius: 4,
                }}
              >
                <Grid container justifyContent="center" spacing={2}>
                  <Grid
                    container
                    justifyContent="center"
                    item
                    xs={12}
                    lg={6}
                    mt={1}
                    sx={{
                      borderRight: { lg: "1px solid #c5c5c5" },
                      padding: "1rem",
                    }}
                    spacing={2}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        sx={{
                          background: "#0063BE",
                          paddingX: 2.5,
                          color: "white",
                          paddingY: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                        }}
                        component="h2"
                      >
                        Tratamiento Fresenius
                      </Typography>
                     
                    </Grid>
                 
                    <Grid
                      container
                      direction="column"
                      item
                      justifyContent="center"
                      spacing={1}
                    >
                      
                      <Grid container item spacing={2}>
                        <Grid item xs={12}>
                                    
                                 
                          <FormControl style={{marginTop:9}} fullWidth >  
                        
                           <InputLabel style={{marginRight:5, fontSize:13}} label='value' id="Lproducto" type="button">Polimericas</InputLabel>
                           
                            <Select 
                              label="Producto"
                              labelId="Lproducto"
                              value={inputF1}
                              onChange={({ target }) =>
                                setInputF1(target.value)
                              }
                              > 
                                {fresenius}
                        
                            </Select> 
                                                                  
                          </FormControl>  

                          <FormControl style={{marginTop:9}} fullWidth >  
                        
                        <InputLabel style={{marginTop:5,fontSize:13}} label='value' id="Lproducto" type="button">Diabetes </InputLabel>                                                          
                         
                         <Select
                           
                           label="Producto"
                           labelId="Lproducto"
                           value={inputF1}
                           onChange={({ target }) =>
                             setInputF1(target.value)
                           }
                           > 
                            {diabetes}
                     
                         </Select>                                              
                                       
                       </FormControl>  

                       <FormControl style={{marginTop:9}} fullWidth >  
                        
                        <InputLabel style={{fontSize:13}} label='value' id="Lproducto" type="button">Pediatricas</InputLabel>
                           
                         <Select
                           label="Producto"
                           labelId="Lproducto"
                           value={inputF1}
                           onChange={({ target }) =>
                             setInputF1(target.value)
                           }
                           > 
                           {pediatricas}
                     
                         </Select>
                                        
                       </FormControl>  
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Precio"                           
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={inputF2}
                            onChange={({ target }) => setInputF2(target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Calorías"
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={inputF3}
                            onChange={({ target }) => setInputF3(target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    item
                    xs={12}
                    lg={6}
                    spacing={2}
                    mt={1}
                    sx={{
                      padding: "1rem",
                    }}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        sx={{
                          background: "#034787",
                          paddingX: 2.5,
                          color: "white",
                          paddingY: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                        }}
                        component="h2"
                      >
                        Otro Tratamiento
                      </Typography>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <FormControl  style={{marginTop:9}} fullWidth>
                          <InputLabel style={{marginTop:5,fontSize:13}} id="Lproducto">Polimericas</InputLabel>
                          <Select
                            label="Producto"
                            labelId="Lproducto"
                            value={inputO1}
                            onChange={({ target }) => setInputO1(target.value)}
                          >
                            {productos.map((item) =>
                              item.type === "otro" && item.categoria === 'polimerica' ? (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name} - {item.presentacionGr}
                                  {item.suministro === "polvo" ? "GR" : "ML"}
                                </MenuItem>
                              ) : (
                                false
                              )
                            )}
                          </Select>
                        </FormControl>
                        <FormControl  style={{marginTop:9}} fullWidth>
                          <InputLabel style={{marginTop:5,fontSize:13}} id="Lproducto">Diabetes</InputLabel>
                          <Select
                            label="Producto"
                            labelId="Lproducto"
                            value={inputO1}
                            onChange={({ target }) => setInputO1(target.value)}
                          >
                            {productos.map((item) =>
                              item.type === "otro" && item.categoria === 'diabetes' ? (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name} - {item.presentacionGr}
                                  {item.suministro === "polvo" ? "GR" : "ML"}
                                </MenuItem>
                              ) : (
                                false
                              )
                            )}
                          </Select>
                        </FormControl>

                        <FormControl  style={{marginTop:9}} fullWidth>
                          <InputLabel style={{marginTop:5,fontSize:13}} id="Lproducto">Pediatricas</InputLabel>
                          <Select
                            label="Producto"
                            labelId="Lproducto"
                            value={inputO1}
                            onChange={({ target }) => setInputO1(target.value)}
                          >
                            {productos.map((item) =>
                              item.type === "otro" && item.categoria === 'pediatrica'? (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name} - {item.presentacionGr}
                                  {item.suministro === "polvo" ? "GR" : "ML"}
                                </MenuItem>
                              ) : (
                                false
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Precio"
                          variant="outlined"
                          type="number"
                          fullWidth
                          value={inputO2}
                          onChange={({ target }) => setInputO2(target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Calorías"
                          type="number"
                          variant="outlined"
                          value={inputO3}
                          fullWidth
                          onChange={({ target }) => setInputO3(target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="center">
                    {!showResult ? (
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={() => resultados()}
                          size="large"
                        >
                          Realizar Calculo
                        </Button>
                      </Grid>
                    ) : (
                      <>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="#034787"
                          textAlign="center"
                        >
                          Desliza hacia abajo para ver los resultados
                        </Typography>
                        <Icon>keyboard_double_arrow_down</Icon>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Card>
              {showResult ? (
                <>
                  <Grid container justifyContent="center" item xs={12} mt={5}>
                    <Typography
                      variant="h5"
                      sx={{
                        background: "#0063BE",
                        paddingX: 2.5,
                        color: "white",
                        paddingY: 0.5,
                        borderRadius: 2,
                        textAlign: "center",
                      }}
                      component="h2"
                    >
                      Resultados
                    </Typography>
                  </Grid>
                  <Card
                    sx={{
                      width: "100%",
                      boxShadow: 4,
                      padding: 3,
                      mt: 2,
                      mb: 2,
                      borderRadius: 4,
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            Producto
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            {productos.find((x) => x.id === inputO1).name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            fontSize={{ lg: 21, xs: 16 }}
                            color="#0063BE"
                          >
                            {productos.find((x) => x.id === inputF1).name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={4}>
                          <Typography
                            fontSize={{ lg: 21, xs: 16 }}
                            fontWeight={500}
                          >
                            Presentación
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            {
                              productos.find((x) => x.id === inputO1)
                                .presentacionGr
                            }
                            {productos.find((x) => x.id === inputO1)
                              .suministro === "polvo"
                              ? "GR"
                              : "ML"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            fontSize={{ lg: 21, xs: 16 }}
                            color="#0063BE"
                          >
                            {
                              productos.find((x) => x.id === inputF1)
                                .presentacionGr
                            }
                            ML
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            Rendimiento Cant. Porción
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            {
                              productos.find((x) => x.id === inputO1)
                                .rendimientoPorciones
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            fontSize={{ lg: 21, xs: 16 }}
                            color="#0063BE"
                          >
                            {
                              productos.find((x) => x.id === inputF1)
                                .rendimientoPorciones
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            Tamaño Porción
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            {
                              productos.find((x) => x.id === inputO1)
                                .tamanoPorcion
                            }
                            &nbsp;
                            {productos.find((x) => x.id === inputO1)
                              .suministro === "liquido"
                              ? "ml"
                              : "gr"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            fontSize={{ lg: 21, xs: 16 }}
                            color="#0063BE"
                          >
                            {
                              productos.find((x) => x.id === inputF1)
                                .tamanoPorcion
                            }
                            &nbsp;
                            {productos.find((x) => x.id === inputF1)
                              .suministro === "liquido"
                              ? "ml"
                              : "gr"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            Cantidad de proteína por porción
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontSize={{ lg: 21, xs: 16 }}>
                            {
                              productos.find((x) => x.id === inputO1)
                                .cantidadProteinaPorPorcion
                            }
                            &nbsp; gr
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            fontSize={{ lg: 21, xs: 16 }}
                            color="#0063BE"
                          >
                            {
                              productos.find((x) => x.id === inputF1)
                                .cantidadProteinaPorPorcion
                            }
                            &nbsp; gr
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        justifyContent="center"
                        xs={12}
                        mt={2}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            background: "#0063BE",
                            paddingX: 2.5,
                            color: "white",
                            paddingY: 0.5,
                            borderRadius: 2,
                            textAlign: "center",
                          }}
                          component="h2"
                        >
                          Diferenciales de Tratamiento
                        </Typography>
                      </Grid>

                      {Array.from(Array(7), (e, i) => (
                        <Iresult
                          key={i}
                          name={nombresResultados[i][0]}
                          o1={productos.find((x) => x.id === inputO1).name}
                          o2={RR["O"][nombresResultados[i][1]]}
                          f1={productos.find((x) => x.id === inputF1).name}
                          f2={RR["F"][nombresResultados[i][1]]}
                        />
                      ))}
                      <Iresult
                        name="Diferencia del tratamiento mes"
                        o1={false}
                        o2={false}
                        f1={" "}
                        f2={RR["A"][0]}
                      />
                      <Iresult
                        name="Ahorro mes"
                        o1={false}
                        bgcolor="#01B22E"
                        o2={false}
                        f1={productos.find((x) => x.id === inputF1).name}
                        f2={RR["A"][1]}
                      />
                      <Iresult
                        name="Ahorro trimestre"
                        o1={false}
                        bgcolor="#01B22E"
                        o2={false}
                        f1={productos.find((x) => x.id === inputF1).name}
                        f2={RR["A"][2]}
                      />
                      <Iresult
                        name="Ahorro total año"
                        o1={false}
                        bgcolor="#01B22E"
                        o2={false}
                        f1={productos.find((x) => x.id === inputF1).name}
                        f2={RR["A"][3]}
                      />

                      <Iresult
                        name="Cantidad de pacientes"
                        o1={false}
                        o2={false}
                        f1={false}
                        f2={false}
                      />

                      <Grid container item justifyContent="center">
                        <TextField
                          type="number"
                          value={inputA1}
                          size="small"
                          inputProps={{
                            min: 1,
                            style: { textAlign: "center" },
                          }}
                          onChange={({ target }) => setInputA1(target.value)}
                          variant="outlined"
                        />
                      </Grid>

                      {productos.find((x) => x.id === inputO1).suministro ===
                      "polvo" ? (
                        <Grid
                          container
                          item
                          justifyContent="center"
                          mt={5}
                          spacing={1}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              sx={{
                                color: "#0063BE",
                                paddingX: 2.5,
                                paddingY: 0.5,
                                fontWeight: "medium",
                                textAlign: "center",
                              }}
                              component="h2"
                            >
                              Insumos en Polvo
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              fontSize={{ xs: 10, lg: 23 }}
                              sx={{
                                color: "#5F6D80",
                                paddingX: 2.5,
                                paddingY: 0,
                                fontWeight: "medium",
                                textAlign: "center",
                              }}
                              component="h2"
                            >
                              {`${
                                productos.find((x) => x.id === inputO1).name
                              } - ${
                                productos.find((x) => x.id === inputO1)
                                  .presentacionGr
                              }`}
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              sx={{
                                color: "#5F6D80",
                                textAlign: "left",
                              }}
                              fontSize={{ xs: 16, lg: 23 }}
                              fontWeight="medium"
                            >
                              Servicio
                            </Typography>
                            <Typography
                              fontSize={{ xs: 10, lg: 23 }}
                              fontWeight="medium"
                              sx={{
                                background: "#0063BE",
                                color: "white",
                                borderRadius: 2,
                                py: 1,
                                textAlign: "center",
                              }}
                            >
                              Hospitales
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="right"
                            xs={3}
                            mt={4}
                          >
                            <img
                              onClick={() => setColumn1(!column1)}
                              src={
                                column1
                                  ? "/avance-rapido12.fw.png"
                                  : "/avance-rapido2.fw.png"
                              }
                              alt="hospital"
                              height={50}
                              style={{ marginRight: 15, cursor: "pointer" }}
                            />
                            <img
                              src="/hospital.png"
                              alt="hospital"
                              height={50}
                            />
                          </Grid>
                          {column1 ? (
                            <>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 16, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  Costo TTO CON POLVOS
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 16, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["O"][5]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  AGUA
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    ml
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG1}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG1(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][1].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  GUANTES
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    UndxPar
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG2}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG2(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][2].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  RECURSO HUMANO
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    T. Minutos
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG3}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG3(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][3].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  OTROS COSTOS
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    Licuadora
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG4}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG4(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][4].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  EQUIPOS ENERGÍA
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    Energía
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG5}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG5(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][5].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO DIA
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][6]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][7]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO MES
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][8]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][9]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO AÑO
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][10]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][11]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Numero de pacientes
                                </Typography>
                              </Grid>
                              <Grid item xs={3} mb={1}>
                                <TextField
                                  size="small"
                                  sx={{ ml: 1, width: "100%" }}
                                  type="number"
                                  value={inputG6}
                                  inputProps={{
                                    min: 1,
                                    style: { textAlign: "right" },
                                  }}
                                  onChange={({ target }) =>
                                    setInputG6(target.value)
                                  }
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  fontSize={{ xs: 12, lg: 23 }}
                                  fontWeight="medium"
                                  sx={{
                                    background: "#0063BE",
                                    color: "white",
                                    borderRadius: 2,
                                    py: 1,
                                    textAlign: "center",
                                  }}
                                >
                                  Costos marginales formula en polvo
                                </Typography>
                              </Grid>
                              <Grid item xs={4}></Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {productos.find((x) => x.id === inputO1).name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {productos.find((x) => x.id === inputF1).name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO MES
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][16]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][17]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Insumos
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][12]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  $ 0
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Total
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][9]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][17]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    DIFERENCIA MES
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][13]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    AHORRO TRIMESTRE
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][14]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    AHORRO AÑO
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][15]}
                                  </Typography>
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            false
                          )}

                          <Grid item xs={9} mt={6}>
                            <Typography
                              sx={{
                                color: "#5F6D80",
                                textAlign: "left",
                              }}
                              fontSize={{ xs: 16, lg: 23 }}
                              fontWeight="medium"
                            >
                              Servicio
                            </Typography>
                            <Typography
                              fontSize={{ xs: 10, lg: 23 }}
                              fontWeight="medium"
                              sx={{
                                background: "#0063BE",
                                color: "white",
                                borderRadius: 2,
                                py: 1,
                                textAlign: "center",
                              }}
                            >
                              Home Care con cuidador
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="right"
                            xs={3}
                            mt={10}
                          >
                            <img
                              onClick={() => setColumn2(!column2)}
                              src={
                                column2
                                  ? "/avance-rapido12.fw.png"
                                  : "/avance-rapido2.fw.png"
                              }
                              alt="hospital"
                              height={50}
                              style={{ marginRight: 15, cursor: "pointer" }}
                            />
                            <img
                              src="/hospice.png"
                              alt="hospital"
                              height={50}
                            />
                          </Grid>

                          {column2 ? (
                            <>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 16, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  Costo TTO CON POLVOS
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 16, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["O"][5]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  AGUA
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    ml
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG1}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG1(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][1].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  GUANTES
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    UndxPar
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG2}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG2(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][2].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  RECURSO HUMANO
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    T. Minutos
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG3}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG3(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][3].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  OTROS COSTOS
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    Licuadora
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG4}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG4(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][4].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  EQUIPOS ENERGÍA
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    Energía
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG5}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG5(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][5].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO DIA
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][6]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][7]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO MES
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][8]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][9]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO AÑO
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][10]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][11]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Numero de pacientes
                                </Typography>
                              </Grid>
                              <Grid item xs={3} mb={1}>
                                <TextField
                                  size="small"
                                  sx={{ ml: 1, width: "100%" }}
                                  type="number"
                                  value={inputG6}
                                  inputProps={{
                                    min: 1,
                                    style: { textAlign: "right" },
                                  }}
                                  onChange={({ target }) =>
                                    setInputG6(target.value)
                                  }
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  fontSize={{ xs: 12, lg: 23 }}
                                  fontWeight="medium"
                                  sx={{
                                    background: "#0063BE",
                                    color: "white",
                                    borderRadius: 2,
                                    py: 1,
                                    textAlign: "center",
                                  }}
                                >
                                  Costos marginales Formula en polvo
                                </Typography>
                              </Grid>
                              <Grid item xs={4}></Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {productos.find((x) => x.id === inputO1).name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {productos.find((x) => x.id === inputF1).name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO MES
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][16]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][17]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Insumos
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][12]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  $ 0
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Total
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][9]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][17]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    DIFERENCIA MES
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][13]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    AHORRO TRIMESTRE
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][14]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    AHORRO AÑO
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][15]}
                                  </Typography>
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            false
                          )}

                          <Grid item xs={9} mt={6}>
                            <Typography
                              sx={{
                                color: "#5F6D80",
                                textAlign: "left",
                              }}
                              fontSize={{ xs: 16, lg: 23 }}
                              fontWeight="medium"
                            >
                              Servicio
                            </Typography>
                            <Typography
                              fontSize={{ xs: 10, lg: 23 }}
                              fontWeight="medium"
                              sx={{
                                background: "#0063BE",
                                color: "white",
                                borderRadius: 2,
                                py: 1,
                                textAlign: "center",
                              }}
                            >
                              Home Care sin cuidador
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="right"
                            xs={3}
                            mt={10}
                          >
                            <img
                              onClick={() => setColumn3(!column3)}
                              src={
                                column3
                                  ? "/avance-rapido12.fw.png"
                                  : "/avance-rapido2.fw.png"
                              }
                              alt="hospital"
                              height={50}
                              style={{ marginRight: 15, cursor: "pointer" }}
                            />
                            <img
                              src="/hospital.png"
                              alt="hospital"
                              height={50}
                            />
                          </Grid>
                          {column3 ? (
                            <>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 16, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  Costo TTO CON POLVOS
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 16, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["O"][5]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  AGUA
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    ml
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG1}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG1(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][1].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  GUANTES
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    UndxPar
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG2}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG2(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][2].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  RECURSO HUMANO
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    T. Minutos
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG3}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG3(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][3].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  OTROS COSTOS
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    Licuadora
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG4}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG4(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][4].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  EQUIPOS ENERGÍA
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      textAlign: "right",
                                      width: "50%",
                                    }}
                                    fontSize={{ xs: 15, lg: 23 }}
                                    fontWeight="medium"
                                  >
                                    Energía
                                  </Typography>
                                  <TextField
                                    size="small"
                                    sx={{ ml: 1, width: "50%" }}
                                    type="number"
                                    value={inputG5}
                                    inputProps={{ min: 0 }}
                                    onChange={({ target }) =>
                                      setInputG5(target.value)
                                    }
                                    variant="outlined"
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][5].toString().replace(" ", "")}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO DIA
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][6]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][7]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO MES
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][8]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][9]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO AÑO
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][10]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <Typography
                                  sx={{
                                    color: "red",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="medium"
                                >
                                  {RR["G"][11]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 15, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Numero de pacientes
                                </Typography>
                              </Grid>
                              <Grid item xs={3} mb={1}>
                                <TextField
                                  size="small"
                                  sx={{ ml: 1, width: "100%" }}
                                  type="number"
                                  value={inputG6}
                                  inputProps={{
                                    min: 1,
                                    style: { textAlign: "right" },
                                  }}
                                  onChange={({ target }) =>
                                    setInputG6(target.value)
                                  }
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  fontSize={{ xs: 12, lg: 23 }}
                                  fontWeight="medium"
                                  sx={{
                                    background: "#0063BE",
                                    color: "white",
                                    borderRadius: 2,
                                    py: 1,
                                    textAlign: "center",
                                  }}
                                >
                                  Costos marginales Formula en polvo
                                </Typography>
                              </Grid>
                              <Grid item xs={4}></Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {productos.find((x) => x.id === inputO1).name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {productos.find((x) => x.id === inputF1).name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  COSTO TTO MES
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][16]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][17]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Insumos
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][12]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  $ 0
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  Total
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#5F6D80",
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][9]}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#0063BE",
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                  fontSize={{ xs: 11, lg: 23 }}
                                  fontWeight="bold"
                                >
                                  {RR["G"][17]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    DIFERENCIA MES
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][13]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    AHORRO TRIMESTRE
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][14]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5F6D80",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    AHORRO AÑO
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#10d006",
                                      width: "100%",
                                      textAlign: "right",
                                    }}
                                    fontSize={{ xs: 11, lg: 23 }}
                                    fontWeight="bold"
                                  >
                                    {RR["G"][15]}
                                  </Typography>
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            false
                          )}
                        </Grid>
                      ) : (
                        false
                      )}
                    </Grid>
                  </Card>
                </>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
        </Grid>
        <Grid item xs={12} mt={2} mb={3} textAlign="center">
          <Typography
            component="a"
            href="https://api.calculadorafresenius.tech/assets/invima.pdf"
            target="_blank"
            variant="subtitle2"
            sx={{
              textDecoration: "underline #0063BE",
              color: "#0063BE",
              cursor: "pointer",
              fontSize: { xs: 15, sm: 20 },
            }}
          >
            Registros INVIMA
          </Typography>
        </Grid>
      </Container>
      <InvimaModal open={invima} setOpen={setInvima} />
    </>
  );
}

export default App;
