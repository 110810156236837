import * as  React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Icon } from "@mui/material";




function ProductosTable({ rows, Cmodal, deleteU, }) {

  

 
  return (
    <TableContainer component={Paper}>
       
      <Table  sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align="right">Editar</TableCell>
            <TableCell align="right">Eliminar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                <Box
                  onClick={() => Cmodal("edit", row.id)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    cursor: "pointer",
                  }}
                >
                  <Icon sx={{ color: "#1976D2" }}>edit</Icon>Editar
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box
                  onClick={() => deleteU(row.id.toString())}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    cursor: "pointer",
                  }}
                >
                  <Icon sx={{ color: "red" }}>delete</Icon>Eliminar
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ProductosTable.propTypes = {
  rows: PropTypes.array.isRequired,
  Cmodal: PropTypes.func.isRequired,
  deleteU: PropTypes.func.isRequired,
};

export default ProductosTable;
